<template>
  <div>
    <div class="Withdrawal">
      <div class="notice">
        <van-notice-bar
          color="#333333"
          background="rgba(248,222,77,0.3)"
          mode="link"
        >
          <template #left-icon>
            <img src="../assets/home/list.png" alt="" />
          </template>
          <template #default>无需手续费，兑换点卡直接购买产品 </template>
        </van-notice-bar>
      </div>
      <div class="tx">
        <div class="type">
          <div class="left">
            提现币种
          </div>
          <div class="right">
            <img src="../assets/home/btc.png" alt="" />
            <span>{{ symbol }}</span>
          </div>
        </div>
        <div class="num-title">
          提现数量
        </div>
        <div class="num">
          <van-field
            class="left"
            v-model="mentionCoinNumber"
            type="number"
            placeholder="最少0.001"
            @update:model-value="textChange(mentionCoinNumber, 2)"
            :border="false"
          />
          <div class="right" @click="allNumber">
            全部
          </div>
        </div>
        <div class="ktye">可提余额：{{ balance }}</div>
        <div class="num-title">
          网络
        </div>
        <div class="num">
          <van-field
            style="font-size: 15px;color: #333333!important;font-weight: bolder;"
            class="left"
            v-model="network"
            type="text"
            :disabled="true"
            placeholder="输入网络"
          />
        </div>
        <div class="num-title">
          提币地址
        </div>
        <div class="num">
          <van-field
            style="font-size: 15px;color: #333333!important;font-weight: bolder;"
            class="left"
            v-model="coinAddress"
            type="text"
            placeholder="输入地址"
            @update:model-value="textChange(coinAddress, 1)"
          />
        </div>
        <div class="ktye">支持划转本链bTC接受地址，一般1或3开头</div>
      </div>
      <div class="kj-sm">
        <div class="footer-title">温馨提示：</div>
        <div class="footer-body">
          1.请仔细核对提现地址，一旦错误提现资产将不可找回，
          若产生损失将由客户承担。 2.每日UTC+8时间
          17:00之前的提现，当日内完成审核。 3.每日UTC+8时间
          17:00之后的提现，次日内完成审核。
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <div>
          实扣
          <span style="font-size: 22px;margin-left:4px;">{{
            accountNumber
          }}</span>
          BTC
        </div>
        <div class="sxf">
          手续费 <span style="margin-left:4px;">{{ withDrawFee }}</span> BTC
        </div>
      </div>
      <van-Button
        @click="withdrawal"
        class="right"
        :class="{ disabled: canWithdrawal }"
        :disabled="!canWithdrawal"
        >提现</van-Button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant"
export default {
  name: "Withdrawal",
  data() {
    return {
      withDrawMin: "", //最小提币数量
      coinAddress: "", //转币地址
      network: "Bitcoin", //网络
      mentionCoinNumber: "", //提币数量
      withDrawFee: "", //手续费
      balance: 0, //可提现额度
      accountNumber: "0.00000000", //到账数量
      canWithdrawal: false,
      mobile: "",
      symbol: "BTC",
    }
  },
  components: {},
  computed: {},
  mounted() {
    // this.symbol = this.$route.query.symbol
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$request
        .post("/user/fee")
        .then(
          (res) => {
            if (res.code == 200) {
              this.withDrawFee = res.data.withDrawFee
              this.withDrawMin = res.data.withDrawMin
              this.balance = res.data.balance
              this.mobile = res.data.mobile
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
    textChange(e, a) {
      if (a === 1) {
        // console.log("这个是第一个输入框");
        this.coinAddress = e
      } else if (a === 2) {
        // console.log("这个是第2个输入框");
        this.mentionCoinNumber = e
        if (e != "") {
          this.accountNumber = this.mentionCoinNumber - this.withDrawFee
        } else {
          this.accountNumber = "0.00000000" //到账数量
        }
      }
      if (this.coinAddress.length > 0 && this.balance.length > 0) {
        this.canWithdrawal = true
      } else {
        this.canWithdrawal = false
      }
    },
    //点击全部
    allNumber() {
      this.mentionCoinNumber = this.balance
      this.accountNumber = this.mentionCoinNumber - this.withDrawFee
    },
    //提现
    withdrawal() {
      const params = {
        withdrawAmount: this.mentionCoinNumber,
        address: this.coinAddress,
        network: this.network,
        symbol: this.symbol,
      }
      this.$request
        .post("/user/withdraw", params)
        .then(
          (res) => {
            if (res.code == 200) {
              this.$toast.success({
                message: "提现成功",
                duration: 1000,
              })
              setTimeout(() => {
                this.$router.push({ path: "/my" })
              }, 1000)
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.Withdrawal {
  font-weight: bolder;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px 90px;
  background: #f8f8f8;

  .notice {
    width: 100%;
    padding: 13px 0 18px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    .van-notice-bar {
      font-size: 12px;
      padding: 0;
      font-weight: bolder;
      line-height: 40px;
      border-radius: 8px;
      padding-right: 5px;
    }
    img {
      margin: 0 5px 0 10px;
      width: 20px;
      height: 20px;
    }
  }

  .tx {
    width: 100%;
    height: 440px;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    .type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: AppleSystemUIFont;
        font-size: 15px;
        color: #666666;
        line-height: 18px;
        text-align: left;
        font-style: normal;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          margin-right: 6px;
        }
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 17px;
        color: #333333;
        line-height: 23px;
        text-align: left;
        font-style: normal;
      }
    }
    .num-title {
      font-family: AppleSystemUIFont;
      font-size: 15px;
      color: #666666;
      line-height: 18px;
      text-align: left;
      font-style: normal;
      margin-top: 20px;
    }
    .num {
      width: 100%;
      height: 52px;
      background: #f9f9f9;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 12px;
      margin-top: 10px;
      .left {
        width: 220px;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        text-align: left;
        font-style: normal;
        background: #f9f9f9;
        padding: 0;
      }
      .right {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 15px;
        color: #f8b34d;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        padding: 0 12px;
      }
    }
    .ktye {
      width: 100%;
      margin-top: 10px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 13px;
      color: #666666;
      line-height: 18px;
      text-align: right;
      font-style: normal;
      padding-right: 4px;
    }
  }
  .kj-sm {
    text-align: left;
    width: 100%;
    margin-top: 10px;
    padding: 14px 0 14px 16px;
    background: #ffffff;
    height: 150px;
    border-radius: 8px;

    .footer-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 8px;
      font-size: 14px;
      color: #ff3500;
    }
    .footer-body {
      width: 100%;
      font-size: 13px;
      color: #4a4a4a;
      line-height: 25px;
      letter-spacing: 1px;
      font-size: 13px;
      color: #999999;
    }
  }
}

.footer {
  padding: 0 20px;
  width: 100%;
  height: 70px;
  margin-top: 20px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  font-weight: bolder;
  color: #999999;
  .right {
    width: 127px;
    height: 50px;
    border-radius: 25px;
    line-height: 50px;
    text-align: center;
    background: #e1e1e1;
    color: #ffffff;
    &.disabled {
      background: #f8de4d;
      color: #4a4a4a;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    .sxf {
      display: inline-block;
      // margin-left: 6px;
      // color: #333333;
    }
    span {
      color: #333333;
    }
  }
}

::placeholder {
  font-size: 15px;
  color: #999999;
  font-weight: bolder;
}
</style>

<style lang="scss">
.van-field__control:disabled {
  color: #333333 !important;
}
</style>
